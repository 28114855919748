import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useLocation, useNavigate } from "react-router-dom";
import AppDrawer from "../../Layout/AppDrawer";
import { ArrowBack, Headphones, MenuBook } from "@mui/icons-material";
import { findDuration, splitDateTime } from "../../Utils/utils";
import { useEffect, useState } from "react";
import { useAuth } from "../../customHooks/AuthContext";
import { getData } from "../../API/apiService";
import axios from "axios";

interface DataItem {
  [key: string]: string[];
}
const PrevSessionDetails = () => {
  const location = useLocation();
  const { patientDetails, sessionDetails } = location.state || {};
  const navigate = useNavigate();
  const { adminPractitionerId, role } = useAuth();
  const [isAudioDownloadLoading, setIsAudioDownloadLoading] = useState(false);
  const [audioUrl, setAudioUrl] = useState("");
  const sessionData = localStorage.getItem("userData");
  const practionerDetails = sessionData ? JSON.parse(sessionData) : {};
  const practionerId = practionerDetails.practionerInfo.id;
  const { date, time } = splitDateTime(
    sessionDetails.audio_created_at
  ) as never;
  const duration = findDuration(
    sessionDetails.audio_created_at,
    sessionDetails.session_end_dttm
  );

  const [sessionTranscriptList, setSessionTranscriptList] = useState<any[]>([]);
  const [sessionSummaryList, setSessionSummaryList] = useState<any[]>([]);
  const [diagnosisList, setDiagnosisList] = useState<any[]>([]);
  const [treatmentPlanList, setTreatmentPlanList] = useState<any[]>([]);

  const handleReportClick = (menu: string) => {
    if (menu === "session_report") {
      navigate("/session-report", {
        state: { patientDetails, sessionid: sessionDetails.id },
      });
    } else {
      navigate("/session-audio", {
        state: { patientDetails, sessionid: sessionDetails.id },
      });
    }
  };

  useEffect(() => {
    fetchSessionTranscript();
    fetchSessionSummary();
    fetchSessionAIDATreatmentPlan();
    fetchSessionAIDADignosis();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchSessionTranscript = async () => {
    try {
      if (adminPractitionerId && role === "admin") {
        const APIResponse = await getData(
          `/transcriptions/${adminPractitionerId}/${patientDetails.id}/${sessionDetails.id}`
        );
        return processSessionTranscriptAPIResponse(APIResponse);
      } else {
        const APIResponse = await getData(
          `/transcriptions/${practionerId}/${patientDetails.id}/${sessionDetails.id}`
        );
        return processSessionTranscriptAPIResponse(APIResponse);
      }
    } catch (error) {
      console.error("Error fetching the files:", error);
      return false; // Continue polling if there's an error
    }
  };
  // Function to process the API response
  const processSessionTranscriptAPIResponse = (APIResponse: any): boolean => {
    if (Array.isArray(APIResponse) && APIResponse.length > 0) {
      const mergedAudioSegments = APIResponse.flatMap(
        (item: { results: { audio_segments: any } }) =>
          item.results.audio_segments
      );
      if (mergedAudioSegments.length) {
        setSessionTranscriptList(mergedAudioSegments);
        return true; // API response is not empty
      } else {
        return false; // API response is empty
      }
    } else if (
      !Array.isArray(APIResponse) &&
      APIResponse !== null &&
      typeof APIResponse === "object"
    ) {
      setSessionTranscriptList([APIResponse]);
      return true; // API response is not empty
    }
    return false; // API response is empty
  };

  const fetchSessionSummary = async () => {
    try {
      if (adminPractitionerId && role === "admin") {
        const APIResponse = await getData(
          `/summaries/${adminPractitionerId}/${patientDetails.id}/${sessionDetails.id}`
        );
        return processSessionSummaryAPIResponse(APIResponse);
      } else {
        const APIResponse = await getData(
          `/summaries/${practionerId}/${patientDetails.id}/${sessionDetails.id}`
        );
        return processSessionSummaryAPIResponse(APIResponse);
      }
    } catch (error) {
      console.error("Error fetching the files:", error);
      return false; // Continue polling if there's an error
    }
  };

  const processSessionSummaryAPIResponse = (APIResponse: any): boolean => {
    if (Array.isArray(APIResponse) && APIResponse.length > 0) {
      const sessionSummary = transformData(APIResponse);
      if (sessionSummary.length) {
        setSessionSummaryList(sessionSummary);
        return true; // API response is not empty
      } else {
        return false; // API response is empty
      }
    } else if (
      !Array.isArray(APIResponse) &&
      APIResponse !== null &&
      typeof APIResponse === "object"
    ) {
      setSessionSummaryList([APIResponse]);
      return true; // API response is not empty
    }
    return false; // API response is empty
  };

  const fetchSessionAIDADignosis = async () => {
    try {
      if (adminPractitionerId && role === "admin") {
        const APIResponse = await getData(
          `/diagnosis/${adminPractitionerId}/${patientDetails.id}/${sessionDetails.id}`
        );
        return processSessionAIDADignosisAPIResponse(APIResponse);
      } else {
        const APIResponse = await getData(
          `/diagnosis/${practionerId}/${patientDetails.id}/${sessionDetails.id}`
        );
        return processSessionAIDADignosisAPIResponse(APIResponse);
      }
    } catch (error) {
      console.error("Error fetching the files:", error);
      return false; // Continue polling if there's an error
    }
  };

  const processSessionAIDADignosisAPIResponse = (APIResponse: any): boolean => {
    if (Array.isArray(APIResponse) && APIResponse.length > 0) {
      const sessionSummary = transformData(APIResponse);
      if (sessionSummary.length) {
        setDiagnosisList(sessionSummary);

        return true; // API response is not empty
      } else {
        return false; // API response is not empty
      }
    } else if (
      !Array.isArray(APIResponse) &&
      APIResponse !== null &&
      typeof APIResponse === "object"
    ) {
      setDiagnosisList([APIResponse]);

      return true; // API response is not empty
    }
    return false; // API response is empty
  };

  const fetchSessionAIDATreatmentPlan = async () => {
    try {
      if (adminPractitionerId && role === "admin") {
        const APIResponse = await getData(
          `/treatment/${adminPractitionerId}/${patientDetails.id}/${sessionDetails.id}`
        );
        return processSessionAIDATreatmenPlantAPIResponse(APIResponse);
      } else {
        const APIResponse = await getData(
          `/treatment/${practionerId}/${patientDetails.id}/${sessionDetails.id}`
        );
        return processSessionAIDATreatmenPlantAPIResponse(APIResponse);
      }
    } catch (error) {
      console.error("Error fetching the files:", error);
      return false; // Continue polling if there's an error
    }
  };

  const processSessionAIDATreatmenPlantAPIResponse = (
    APIResponse: any
  ): boolean => {
    if (Array.isArray(APIResponse) && APIResponse.length > 0) {
      const isNoTreatment = checkNoTreatement(APIResponse);
      const treatmentPlan = transformData(APIResponse);
      if (isNoTreatment) {
        setTreatmentPlanList(treatmentPlan);
        return true; // No Need call Chunking API
      }
      if (treatmentPlan.length) {
        setTreatmentPlanList(treatmentPlan);
        return true; // API response is not empty
      } else {
        return false;
      }
    } else if (
      !Array.isArray(APIResponse) &&
      APIResponse !== null &&
      typeof APIResponse === "object"
    ) {
      setTreatmentPlanList([APIResponse]);
      return true; // API response is not empty
    }
    return false; // API response is empty
  };

  const transformData = (input: DataItem[]): DataItem[] => {
    return input.reduce((acc: DataItem[], obj: DataItem) => {
      Object.keys(obj).forEach((key: string) => {
        acc.push({ [key]: obj[key] });
      });
      return acc;
    }, []);
  };

  const checkNoTreatement = (response: any) => {
    const expectedResponse = [
      {
        Treatment:
          "There is currently no treatment plan available for this session.",
      },
    ];
    const isSameResponse =
      JSON.stringify(response) === JSON.stringify(expectedResponse);

    return isSameResponse;
  };

  const downloadReport = () => {
    // Step 1: Create the content for the file
    // Create text content dynamically using map
    const filesToDownload = [];

    // Session Transcript
    const sessionTranscriptContent = sessionTranscriptList
      .map((item, i) => `${item.speaker_label}: ${item.transcript}`)
      .join("\n"); // Join each transcript item with a new line

    const sessionTranscriptContentFileName = `${patientDetails.first_name}-${patientDetails.last_name} SessionTranscript.txt`;

    filesToDownload.push({
      content: sessionTranscriptContent,
      fileName: sessionTranscriptContentFileName,
    });

    // Session Treatment Plan
    const sessionTreatmentPlanContent = treatmentPlanList
      .map((item: Record<string, any>) =>
        Object.entries(item)
          .map(([key, value]) =>
            Array.isArray(value) && value.length > 0
              ? `${key}: ${value.join(", ")}`
              : !Array.isArray(value) && value
              ? `${key}: ${String(value)}`
              : ""
          )
          .filter(Boolean)
          .join("\n")
      )
      .join("\n");

    const sessionTreatmentPlanFileName = `${patientDetails.first_name}-${patientDetails.last_name} AIDATreatmentPlan.txt`;

    filesToDownload.push({
      content: sessionTreatmentPlanContent,
      fileName: sessionTreatmentPlanFileName,
    });

    // Sesssion Summary
    const sessionSummaryContent = sessionSummaryList
      .map((item: Record<string, any>) =>
        Object.entries(item)
          .map(([key, value]) =>
            Array.isArray(value) && value.length > 0
              ? `${key}: ${value.join(", ")}`
              : !Array.isArray(value) && value
              ? `${key}: ${String(value)}`
              : ""
          )
          .filter(Boolean)
          .join("\n")
      )
      .join("\n");

    const sessionSummaryFileName = `${patientDetails.first_name}-${patientDetails.last_name} Session-Notes.txt`;

    filesToDownload.push({
      content: sessionSummaryContent,
      fileName: sessionSummaryFileName,
    });

    // Session Dignosis
    const sessionDignosisContent = diagnosisList
      .map((item: Record<string, any>) =>
        Object.entries(item)
          .map(([key, value]) =>
            Array.isArray(value) && value.length > 0
              ? `${key}: ${value.join(", ")}`
              : !Array.isArray(value) && value
              ? `${key}: ${String(value)}`
              : ""
          )
          .filter(Boolean)
          .join("\n")
      )
      .join("\n");

    const sessionAIDADignosisFileName = `${patientDetails.first_name}-${patientDetails.last_name} AIDA-Diagnosis.txt`;

    filesToDownload.push({
      content: sessionDignosisContent,
      fileName: sessionAIDADignosisFileName,
    });

    filesToDownload.forEach((file) => {
      const blob = new Blob([file.content], { type: "text/plain" });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = file.fileName;
      document.body.appendChild(a); // Append the anchor to the document body
      a.click(); // Programmatically click the anchor to trigger the download
      document.body.removeChild(a); // Remove the anchor after download
      URL.revokeObjectURL(url); // Clean up the URL object
    });
  };

  const handleAudioDownload = async () => {
    try {
      setIsAudioDownloadLoading(true);
      const token = localStorage.getItem("accessToken");
      const APIResponse = await axios.get(
        `https://u1np3hd5ub.execute-api.us-east-1.amazonaws.com/prod/v1/fullAudio/${practionerDetails.practionerInfo.id}/${patientDetails.id}/${sessionDetails.id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Example for Bearer token
          },
        }
      );
      if (APIResponse) {
        setAudioUrl(APIResponse.data.presigned_url);
        // Create a temporary anchor element
        const link = document.createElement("a");
        link.href = APIResponse.data.presigned_url;
        link.setAttribute("download", "audio-file.wav"); // Specify the file name
        document.body.appendChild(link);

        // Trigger the download by simulating a click
        link.click();

        // Clean up by removing the anchor element
        document.body.removeChild(link);

        setIsAudioDownloadLoading(false);
      }
    } catch (error) {
      setIsAudioDownloadLoading(false);
    }
  };

  return (
    <AppDrawer>
      <Paper
        variant="outlined"
        component="fieldset"
        sx={{
          backgroundColor: "#ffcc00",
          borderRadius: "10px",
        }}
      >
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <Typography
              variant="h6"
              sx={{ fontWeight: "bold", mb: 3, fontSize: "medium" }}
            >
              {patientDetails?.first_name ? patientDetails.first_name : "-"}{" "}
              {patientDetails?.last_name ? patientDetails.last_name : "-"}
              <Divider
                sx={{ backgroundColor: "#ffcc00", borderBottomWidth: 2 }}
              ></Divider>
            </Typography>
          </Grid>
          <Grid item>
            {/* Audio Player */}
            <audio controls style={{ display: "none" }}>
              <source src={audioUrl} type="audio/wav" />
              Your browser does not support the audio tag.
            </audio>
            <Button
              size="small"
              variant="contained"
              startIcon={<ArrowBack />}
              style={{
                backgroundColor: "#ffcc00",
                borderRadius: "05px",
                border: "1px solid black",
                color: "black",
              }}
              onClick={() =>
                navigate("/patient-details", {
                  state: { patientDetails: patientDetails },
                })
              }
            >
              Back
            </Button>
          </Grid>
        </Grid>
        <Box height={10}></Box>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={3}
          sx={{ fontWeight: "bold", fontSize: "small" }}
        >
          <Grid item xs={3}>
            Initial Consultation
          </Grid>
          <Grid item xs={3}>
            Appointment Time
          </Grid>
          <Grid item xs={2}>
            Date
          </Grid>
          <Grid item xs={2}>
            Duration
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={3}
        >
          <Grid item xs={3} sx={{ fontSize: "small" }}>
            {date}
          </Grid>
          <Grid item xs={3} sx={{ fontSize: "small" }}>
            {time}
          </Grid>

          <Grid item xs={2} sx={{ fontSize: "small" }}>
            {date}
          </Grid>
          <Grid item xs={2} sx={{ fontSize: "small" }}>
            {duration} Min
          </Grid>
        </Grid>
      </Paper>
      <Box height={10}></Box>
      <Grid
        container
        direction="row"
        spacing={2}
        sx={{
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid item xs={2}>
          <MenuBook />
        </Grid>
        <Grid item xs={4} style={{ fontWeight: "bold" }}>
          Session Report
        </Grid>
        <Grid item xs={2}>
          <Button
            size="small"
            startIcon={<VisibilityIcon sx={{ color: "#ffcc00" }} />}
            sx={{ textTransform: "none", color: "black", cursor: "pointer" }}
            onClick={() => handleReportClick("session_report")}
          >
            View More
          </Button>
        </Grid>
        <Grid item xs={2}>
          <Button
            size="small"
            endIcon={<DownloadIcon />}
            sx={{ textTransform: "none", color: "black" }}
            onClick={downloadReport}
          >
            Download
          </Button>
        </Grid>
      </Grid>

      <Box height={10}></Box>
      <Divider />
      <Box height={10}></Box>
      <Grid
        container
        direction="row"
        spacing={2}
        sx={{
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid item xs={2}>
          <Headphones />
        </Grid>
        <Grid item xs={4} style={{ fontWeight: "bold", cursor: "pointer" }}>
          Session Audio
        </Grid>
        <Grid item xs={2}>
          <Button
            size="small"
            startIcon={<VisibilityIcon sx={{ color: "#ffcc00" }} />}
            sx={{ textTransform: "none", color: "black" }}
            onClick={() => handleReportClick("session_audio")}
          >
            View More
          </Button>
        </Grid>
        <Grid item xs={2}>
          {/* <Button
            size="small"
            endIcon={<DownloadIcon />}
            sx={{ textTransform: "none", color: "black" }}
            onClick={handleAudioDownload}
          >
            Download
          </Button> */}

          <Button
            size="small"
            endIcon={!isAudioDownloadLoading ? <DownloadIcon /> : null}
            sx={{ textTransform: "none", color: "black" }}
            onClick={handleAudioDownload}
            disabled={isAudioDownloadLoading} // Disable button when loading
          >
            {isAudioDownloadLoading ? (
              <CircularProgress size={20} />
            ) : (
              "Download"
            )}{" "}
            {/* Show spinner when loading */}
          </Button>
        </Grid>
      </Grid>
    </AppDrawer>
  );
};

export default PrevSessionDetails;
